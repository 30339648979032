/*******************************
         Site Overrides
*******************************/

.ui.form input:read-only,
.ui.form textarea:read-only,
.ui.form input:read-only:focus,
.ui.form textarea:read-only:focus {
	background-color: white;

}

.ui.form input:read-only:focus,
.ui.form textarea:read-only:focus {
	border: @inputBorder;
}